import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBlogsAllNews } from "../action/MainAction";
import DOMPurify from "dompurify";
import { Link } from "react-router-dom";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import Loading from "./Loading";
import {
  getBlogsAllNewsFunc,
  isLoadingFunc,
  isLoadingStart,
} from "../redux/MainReducer";
import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/classic.css";
import axios from "axios";

const News = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.Data.loading);

  // Вызов переменной содержащий данные о партеров
  const blogsAllNewsValue = useSelector(
    (state) => state.Data.blogsAllNewsValue
  );
  // Используем код языка для отображения url www.site.com/en/...
  const lang = i18next?.language.toLowerCase();

  // Вызов api/blogs/
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(isLoadingStart()); // Запускаем загрузку
    if (i18next.language !== "dev") {
      dispatch(getBlogsAllNews(lang));
    }
    
  }, [dispatch, i18next.language]);

  //  Получаемый дата объект присвоение к массиву
  const blogsArr = blogsAllNewsValue.results;

  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(blogsAllNewsValue?.count / 12);

  // Смена показа продуктов при нажатии на числа пагинации
  const handlePageClick = async (page) => {
    dispatch(isLoadingStart()); // Устанавливаем загрузку при нажатии на числа пагинации

    window.scrollTo({ top: 0, behavior: "smooth" }); // Скроллим наверх
    setCurrentPage(page); // Устанавливаем текущую страницу

    try {
      if (page === 1) {
        setTimeout(() => {
          dispatch(getBlogsAllNews(i18next.language.toLowerCase())); // Если первая страница, вызываем готовый action
        }, 1000);
        dispatch(isLoadingStart()); // Устанавливаем загрузку
      } else if (blogsAllNewsValue?.next || blogsAllNewsValue?.previous) {
        // Если есть следующая или предыдущая страница, запрашиваем её
        const response = await axios.get(
          `https://buzgroupapi-eyb7ffhde4hvh4ex.germanywestcentral-01.azurewebsites.net/api/blogs/${lang}/?page=${page}`
        );
        setTimeout(() => {
          dispatch(getBlogsAllNewsFunc(response.data));
        }, 1000);
      }
    } catch (error) {
      console.error("Ошибка при загрузке:", error);
      // Если ошибка при нажатии при вызове, вызываем готовый action
      dispatch(getBlogsAllNewsFunc([]));
    } finally {
      setTimeout(() => {
        dispatch(isLoadingFunc()); // Устанавливаем loading в false через 2 секунды
      }, 1000);
      console.log("Загрузка завершена"); // Отключаем загрузку
    }
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div data-aos="zoom-in-up" className="max-w-[1200px] mx-auto my-16">
          <div className="grid grid-cols-1 sm:grid-cols-2 place-items-center gap-2">
            {blogsArr?.map((data, i) => (
              <div className="max-w-[588px] p-5" key={i}>
                <div className="w-full">
                  <img
                    src={`https://buzgroupapi-eyb7ffhde4hvh4ex.germanywestcentral-01.azurewebsites.net/${data?.image}`}
                    alt={`Buz Group New About ${data?.title}`}
                    className="w-full h-full"
                  />
                </div>
                <h6 className="text-xl mt-4 h-[28px] overflow-hidden ">
                  {data?.title}
                </h6>
                <p
                  className="h-[48px]  my-2 leading-6 overflow-hidden"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(data?.content),
                  }}
                ></p>

                <Link to={`/${lang}/singlenewspage/${data?.id}`}>
                  <button className="font-semibold text-[#5e2d7a] hover:text-white hover:px-3 py-2 hover:bg-[#5e2d7a] ease-in-out duration-300 rounded-lg">
                    {t("viewDetails")} →
                  </button>
                </Link>
              </div>
            ))}
          </div>
          {/* Pagination */}
          <ResponsivePagination
            current={currentPage}
            total={totalPages}
            onPageChange={(page) => handlePageClick(page)}
          />
        </div>
      )}
    </>
  );
};

export default News;
