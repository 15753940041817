import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNewsID } from "../action/MainAction";
import { Link, useParams } from "react-router-dom";
import DOMPurify from "dompurify";
import { isLoadingStart } from "../redux/MainReducer";
import Loading from "./Loading";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const SingleNews = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation()

  // Вызов api/blogs/id/az/ проверка наличия id, а потом отправка запроса
  useEffect(() => {
    

    window.scrollTo(0, 0); // Для показа страницы с головы
    dispatch(isLoadingStart())
    if (id) {
      dispatch(getNewsID(id));
    }
  }, [dispatch, id]);

  const newsIDvalue = useSelector((state) => state.Data.newsIDvalue);
  const loading = useSelector((state) => state.Data.loading);

   // Используем код языка для отображения url www.site.com/en/...
   const lang = i18next?.language.toLowerCase()
    

  return (
    <>
    {loading?<Loading/>:
    <>
    
          <div className="max-w-[1224px] mx-auto px-3 my-24 mb-[18rem]">
            <h4 className="text-xl font-semibold">{newsIDvalue?.title}</h4>
            <div className="mt-8 flex flex-col sm:block">
              <div className="sm:max-w-[348px] md:max-w-[448px] lg:max-w-[548px] float-right sm:ml-4">
                <img
                  src={`https://buzgroupapi-eyb7ffhde4hvh4ex.germanywestcentral-01.azurewebsites.net/${newsIDvalue?.image}`}
                  alt={`BUZ GROUP NEWS ${newsIDvalue?.title}`}
                  className="w-full"
                />
              </div>
              <p
                className="text-justify leading-8"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(newsIDvalue?.content),
                }}
              ></p>
            </div>
          </div>
          <div className="bg-gradient-to-r from-[#50B3C1] to-[#5E2D7A] py-24">
            <div className="max-w-[1224px] mx-auto text-white px-3">
              <h4 className="text-xl font-semibold mb-10">{t('chooseUs')}</h4>
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-x-16 gap-y-7">
                <p className="max-w-[710px] leading-7">
                  {t('collaborationBenefits')}
                </p>
                <Link to={`/${lang}/about`}>
                  <button className="text-[#50B3C1] hover:text-white border border-white bg-white hover:bg-[#50B3C1] duration-500 rounded px-10 py-3 lg:px-16 lg:py-5">
                    {t('aboutUs')}
                  </button>
                
                </Link>
              </div>
            </div>
          </div>
    </>
    }
    </>
  );
};

export default SingleNews;
