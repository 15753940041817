import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getContacts } from '../action/MainAction'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import i18next from 'i18next'

const Footer = () => { 

  const { t } = useTranslation();

  const dispatch = useDispatch()

  // Вызов переменной содержащий данные о контактов
  const contactsValue = useSelector(state=>state.Data.contactsValue)

  // Вызов api/contacts/az/
  useEffect(()=>{
    if(i18next.language!=="dev"){
      
      dispatch(getContacts(i18next.language.toLowerCase()))
    }
  },[dispatch, i18next.language])

    // Используем код языка для отображения url www.site.com/en/...
    const lang = i18next?.language.toLowerCase()


  return (
    <footer className="bg-[#141130] pt-14 pb-12">
      <div className="max-w-[1324px] mx-auto px-7 text-white border border-x-0 border-t-0 border-[#D4D0CA]">
        <div className="flex flex-wrap sm:justify-between gap-x-10 gap-y-7 mb-16">
          <div className="w-full sm:w-auto">

          <Link to={`${lang}/contact`}>
            <p className="flex items-center gap-x-1">{t('contact')}<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.74023 16.2599L16.2591 3.74106" stroke="#50B3C1" strokeWidth="2.2615" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M7.92578 3.74106H16.2591V12.0744" stroke="#50B3C1" strokeWidth="2.2615" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </p>
          
          </Link>
            {contactsValue[0]?.phone_numbers?.map((data, i)=>(

              <p key={i} className="flex items-center gap-x-1 my-3"><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_537_12)">
              <path d="M18.3332 16.6004V14.1004C18.3433 13.6917 18.2029 13.2936 17.9386 12.9817C17.6744 12.6697 17.3047 12.4658 16.8999 12.4087C16.0998 12.3032 15.3143 12.1075 14.5582 11.8254C14.2599 11.7133 13.9358 11.689 13.6241 11.7555C13.3125 11.8219 13.0264 11.9764 12.7999 12.2004L11.7415 13.2587C9.65524 12.0724 7.92783 10.345 6.74153 8.25874L7.79986 7.20041C8.02392 6.97383 8.17832 6.68778 8.24479 6.37614C8.31125 6.0645 8.28698 5.74034 8.17486 5.44207C7.89274 4.68601 7.69705 3.90046 7.59153 3.10041C7.53493 2.69995 7.33464 2.33373 7.028 2.07002C6.72136 1.80631 6.32929 1.66309 5.92486 1.66707H3.42486C3.19348 1.66729 2.96468 1.71569 2.75302 1.80917C2.54137 1.90266 2.3515 2.0392 2.1955 2.21009C2.03951 2.38098 1.9208 2.58248 1.84695 2.80176C1.77309 3.02104 1.7457 3.2533 1.76653 3.48374C2.04007 6.05957 2.91651 8.53474 4.32486 10.7087C5.60431 12.7222 7.31139 14.4293 9.32486 15.7087C11.489 17.1122 13.9522 17.9884 16.5165 18.2671C16.7477 18.288 16.9806 18.2603 17.2005 18.186C17.4203 18.1116 17.6222 17.9922 17.7933 17.8353C17.9643 17.6784 18.1006 17.4875 18.1936 17.2749C18.2866 17.0622 18.3341 16.8325 18.3332 16.6004Z" stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M11.667 1.66699C13.3655 1.84597 14.9521 2.59932 16.1644 3.80248C17.3766 5.00563 18.1419 6.5865 18.3337 8.28366M11.667 5.00033C12.4866 5.16194 13.2387 5.56618 13.8256 6.16058C14.4126 6.75498 14.8074 7.5121 14.9587 8.33366" stroke="#50B3C1" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
              </g>
              <defs>
              <clipPath id="clip0_537_12">
              <rect width="20" height="20" fill="white" transform="translate(0 0.000488281)"/>
              </clipPath>
              </defs>
                  </svg>{data}</p>

                ))}
             
          </div>
            <div>
              <p className="flex items-center gap-x-1 mb-3">{t('services')}<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.74023 16.2599L16.2591 3.74106" stroke="#50B3C1" strokeWidth="2.2615" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M7.92578 3.74106H16.2591V12.0744" stroke="#50B3C1" strokeWidth="2.2615" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </p>
              <Link to={`${lang}/newspage`}>
              <p className="flex items-center gap-x-1">{t('news')}<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.74023 16.2599L16.2591 3.74106" stroke="#50B3C1" strokeWidth="2.2615" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M7.92578 3.74106H16.2591V12.0744" stroke="#50B3C1" strokeWidth="2.2615" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </p>
              
              </Link>
            </div>
            <div className="w-full max-w-[172px]">
            <Link to={`${lang}/about`}>
              <p className="flex items-center gap-x-1 mb-3">{t('aboutUs')}<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.74023 16.2599L16.2591 3.74106" stroke="#50B3C1" strokeWidth="2.2615" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M7.92578 3.74106H16.2591V12.0744" stroke="#50B3C1" strokeWidth="2.2615" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </p>
            
            </Link>
            <Link to={`${lang}/partnerspage`}>
              <p className="flex items-center gap-x-1">{t('partners')}<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.74023 16.2599L16.2591 3.74106" stroke="#50B3C1" strokeWidth="2.2615" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M7.92578 3.74106H16.2591V12.0744" stroke="#50B3C1" strokeWidth="2.2615" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </p>
            
            </Link> 
            </div>
        </div>
        <div className="flex justify-between flex-wrap gap-x-10 gap-y-7 mb-7">
          <div className='w-[336px] space-y-3'>
            <p className="text-lg">{t('address')}</p>
            {
                contactsValue[0]?.addresses?.map((data, i) => (
                  <div className="flex items-start gap-x-1" key={i}>
                    <svg width="30" height="30" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10 11.2505C9.38194 11.2505 8.77775 11.0672 8.26384 10.7238C7.74994 10.3805 7.3494 9.89239 7.11288 9.32138C6.87635 8.75036 6.81447 8.12202 6.93505 7.51583C7.05563 6.90964 7.35325 6.35282 7.79029 5.91578C8.22733 5.47874 8.78415 5.18111 9.39034 5.06054C9.99654 4.93996 10.6249 5.00184 11.1959 5.23837C11.7669 5.47489 12.255 5.87543 12.5983 6.38933C12.9417 6.90324 13.125 7.50742 13.125 8.12549C13.124 8.95399 12.7945 9.74827 12.2086 10.3341C11.6228 10.9199 10.8285 11.2495 10 11.2505ZM10 6.25049C9.62916 6.25049 9.26665 6.36046 8.95831 6.56648C8.64997 6.77251 8.40964 7.06535 8.26773 7.40796C8.12581 7.75057 8.08868 8.12757 8.16103 8.49128C8.23338 8.855 8.41195 9.18909 8.67418 9.45132C8.9364 9.71354 9.27049 9.89212 9.63421 9.96446C9.99792 10.0368 10.3749 9.99968 10.7175 9.85776C11.0601 9.71585 11.353 9.47553 11.559 9.16718C11.765 8.85884 11.875 8.49633 11.875 8.12549C11.8745 7.62836 11.6768 7.15174 11.3253 6.80021C10.9738 6.44869 10.4971 6.25099 10 6.25049Z" fill="#50B3C1"/>
                      <path d="M10 18.7505L4.7275 12.5324C4.65424 12.439 4.58174 12.345 4.51 12.2505C3.60982 11.0639 3.12333 9.61492 3.125 8.12549C3.125 6.30212 3.84933 4.55344 5.13865 3.26413C6.42796 1.97482 8.17664 1.25049 10 1.25049C11.8234 1.25049 13.5721 1.97482 14.8614 3.26413C16.1507 4.55344 16.875 6.30212 16.875 8.12549C16.8765 9.61421 16.3902 11.0624 15.4906 12.2486L15.49 12.2505C15.49 12.2505 15.3025 12.4967 15.2744 12.5299L10 18.7505ZM5.50813 11.4974C5.50813 11.4974 5.65375 11.6899 5.68688 11.7311L10 16.818L14.3188 11.7242C14.3463 11.6899 14.4925 11.4961 14.4931 11.4955C15.2289 10.5262 15.6265 9.34239 15.625 8.12549C15.625 6.63365 15.0324 5.2029 13.9775 4.14801C12.9226 3.09312 11.4918 2.50049 10 2.50049C8.50816 2.50049 7.07742 3.09312 6.02253 4.14801C4.96764 5.2029 4.375 6.63365 4.375 8.12549C4.37346 9.34318 4.77153 10.5277 5.50813 11.4974Z" fill="white"/>
                    </svg>
                    <p>{data}</p>
                  </div>
                ))
            }


          </div>
          <div className='space-y-3'>
            <p className="text-lg">E-mail:</p>
                  {
                    contactsValue[0]?.emails?.map((data, i)=>(
                        <p key={i} className="flex items-center gap-x-1"><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" >
                          <path d="M16.5625 3.75049H3.4375C2.57456 3.75049 1.875 4.45004 1.875 5.31299V14.688C1.875 15.5509 2.57456 16.2505 3.4375 16.2505H16.5625C17.4254 16.2505 18.125 15.5509 18.125 14.688V5.31299C18.125 4.45004 17.4254 3.75049 16.5625 3.75049Z" stroke="white" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
                          <path d="M4.375 6.25049L10 10.6255L15.625 6.25049" stroke="#50B3C1" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
                          </svg>{data}
                        </p>
                    ))
                  }
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center gap-x-3 mt-7">
        <div className="bg-white bg-opacity-10 hover:bg-[#50b3c1] hover:bg-opacity-100 ease-in-out duration-500 rounded-full p-3"><svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.80009 0.619537H18.2001C22.162 0.619537 25.381 3.83859 25.381 7.80049V18.2005C25.381 20.105 24.6245 21.9315 23.2778 23.2782C21.9311 24.6249 20.1046 25.3814 18.2001 25.3814H7.80009C3.83819 25.3814 0.619141 22.1624 0.619141 18.2005V7.80049C0.619141 5.89598 1.3757 4.06948 2.72239 2.72279C4.06908 1.3761 5.89559 0.619537 7.80009 0.619537ZM7.55247 3.09573C6.37037 3.09573 5.23667 3.56532 4.4008 4.40119C3.56492 5.23707 3.09533 6.37076 3.09533 7.55287V18.4481C3.09533 20.9119 5.08866 22.9053 7.55247 22.9053H18.4477C19.6298 22.9053 20.7635 22.4357 21.5994 21.5998C22.4353 20.7639 22.9049 19.6302 22.9049 18.4481V7.55287C22.9049 5.08906 20.9115 3.09573 18.4477 3.09573H7.55247ZM19.5001 4.95287C19.9105 4.95287 20.3042 5.11592 20.5944 5.40616C20.8847 5.69639 21.0477 6.09004 21.0477 6.50049C21.0477 6.91094 20.8847 7.30459 20.5944 7.59482C20.3042 7.88506 19.9105 8.04811 19.5001 8.04811C19.0896 8.04811 18.696 7.88506 18.4058 7.59482C18.1155 7.30459 17.9525 6.91094 17.9525 6.50049C17.9525 6.09004 18.1155 5.69639 18.4058 5.40616C18.696 5.11592 19.0896 4.95287 19.5001 4.95287ZM13.0001 6.81001C14.6419 6.81001 16.2165 7.46222 17.3774 8.62316C18.5384 9.7841 19.1906 11.3587 19.1906 13.0005C19.1906 14.6423 18.5384 16.2169 17.3774 17.3778C16.2165 18.5388 14.6419 19.191 13.0001 19.191C11.3583 19.191 9.7837 18.5388 8.62276 17.3778C7.46183 16.2169 6.80962 14.6423 6.80962 13.0005C6.80962 11.3587 7.46183 9.7841 8.62276 8.62316C9.7837 7.46222 11.3583 6.81001 13.0001 6.81001ZM13.0001 9.2862C12.015 9.2862 11.0703 9.67753 10.3737 10.3741C9.67713 11.0707 9.28581 12.0154 9.28581 13.0005C9.28581 13.9856 9.67713 14.9303 10.3737 15.6269C11.0703 16.3235 12.015 16.7148 13.0001 16.7148C13.9852 16.7148 14.9299 16.3235 15.6265 15.6269C16.3231 14.9303 16.7144 13.9856 16.7144 13.0005C16.7144 12.0154 16.3231 11.0707 15.6265 10.3741C14.9299 9.67753 13.9852 9.2862 13.0001 9.2862Z" fill="white"/>
          </svg>
        </div>
        <div className="bg-white bg-opacity-10 hover:bg-[#50b3c1] hover:bg-opacity-100 ease-in-out duration-500 rounded-full p-3"><svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M20.4003 0.857529C21.0571 0.857529 21.6869 1.11841 22.1513 1.58279C22.6156 2.04716 22.8765 2.67699 22.8765 3.33372V20.6671C22.8765 21.3238 22.6156 21.9536 22.1513 22.418C21.6869 22.8824 21.0571 23.1432 20.4003 23.1432H3.06701C2.41028 23.1432 1.78046 22.8824 1.31608 22.418C0.851704 21.9536 0.59082 21.3238 0.59082 20.6671V3.33372C0.59082 2.67699 0.851704 2.04716 1.31608 1.58279C1.78046 1.11841 2.41028 0.857529 3.06701 0.857529H20.4003ZM19.7813 20.048V13.4861C19.7813 12.4156 19.3561 11.389 18.5991 10.6321C17.8422 9.87515 16.8156 9.44991 15.7451 9.44991C14.6927 9.44991 13.467 10.0937 12.8727 11.0594V9.68515H9.41844V20.048H12.8727V13.9442C12.8727 12.9909 13.6403 12.2109 14.5937 12.2109C15.0534 12.2109 15.4943 12.3935 15.8193 12.7185C16.1444 13.0436 16.327 13.4845 16.327 13.9442V20.048H19.7813ZM5.39463 7.74134C5.94628 7.74134 6.47534 7.5222 6.86541 7.13212C7.25549 6.74204 7.47463 6.21299 7.47463 5.66134C7.47463 4.50991 6.54606 3.56896 5.39463 3.56896C4.8397 3.56896 4.30749 3.7894 3.91509 4.1818C3.5227 4.5742 3.30225 5.1064 3.30225 5.66134C3.30225 6.81277 4.2432 7.74134 5.39463 7.74134ZM7.11558 20.048V9.68515H3.68606V20.048H7.11558Z" fill="white"/>
          </svg>
        </div>
        <div className="bg-white bg-opacity-10 hover:bg-[#50b3c1] hover:bg-opacity-100 ease-in-out duration-500 rounded-full p-3"><svg width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.1617 4.34896C15.3153 3.38278 14.8489 2.14197 14.8493 0.857529H11.0236V16.2099C10.9941 17.0407 10.6433 17.8276 10.0452 18.405C9.44706 18.9823 8.64823 19.305 7.81691 19.3051C6.05881 19.3051 4.59786 17.869 4.59786 16.0861C4.59786 13.9566 6.6531 12.3594 8.77024 13.0156V9.10324C4.49881 8.53372 0.759766 11.8518 0.759766 16.0861C0.759766 20.209 4.17691 23.1432 7.80453 23.1432C11.6921 23.1432 14.8493 19.9861 14.8493 16.0861V8.29848C16.4006 9.41258 18.2632 10.0103 20.1731 10.0071V6.18134C20.1731 6.18134 17.8455 6.29277 16.1617 4.34896Z" fill="white"/>
          </svg>
        </div>
        <div className="bg-white bg-opacity-10 hover:bg-[#50b3c1] hover:bg-opacity-100 ease-in-out duration-500 rounded-full p-3"><svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.2003 0.668961C6.39076 0.668961 0.819336 6.22801 0.819336 13.0747C0.819336 19.2652 5.35076 24.4032 11.2689 25.3318V16.6652H8.1241V13.0747H11.2689V10.3385C11.2689 7.23087 13.1136 5.52229 15.9489 5.52229C17.2984 5.52229 18.7098 5.75753 18.7098 5.75753V8.81563H17.1498C15.6146 8.81563 15.1317 9.76896 15.1317 10.7471V13.0747H18.5736L18.0165 16.6652H15.1317V25.3318C18.0492 24.871 20.7059 23.3825 22.6221 21.1347C24.5383 18.887 25.5879 16.0283 25.5812 13.0747C25.5812 6.22801 20.0098 0.668961 13.2003 0.668961Z" fill="white"/>
          </svg>
        </div>
      </div>
    </footer>
  )
}

export default Footer