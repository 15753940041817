import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPartnersAbout, getPartnersData } from '../action/MainAction'
import Loading from './Loading'
import { useTranslation } from 'react-i18next'
import { isLoadingStart } from '../redux/MainReducer'
import i18next from 'i18next'

const Partners = () => {

const dispatch = useDispatch();
const { t } = useTranslation();

const loading = useSelector((state) => state.Data.loading);

// Вызов api/partners/
useEffect(() => {
  dispatch(isLoadingStart());
  window.scrollTo(0, 0);

  dispatch(getPartnersData()); //Вызываем функцию для списка партнеров
  if (i18next.language !== "dev") {
    dispatch(getPartnersAbout(i18next.language.toLowerCase()));
  }
}, [dispatch]);

// Вызов переменной содержащий данные о партнерах
const partnersAboutValue = useSelector(
  (state) => state.Data.partnersAboutValue
);
      

  return (
    <>
    {loading?<Loading/>:
      <div className="pt-[70px] pb-24">
        {/* <div data-aos="zoom-in"></div> */}
        <div data-aos="zoom-in" className="max-w-[934px] xl:max-w-[1224px] mx-auto space-y-5 px-3">
          <h1 className="text-[32px] font-semibold">{t('partners')}</h1>
          <p>
            {partnersAboutValue?.text}
          </p>
          <div className="bg-[#50B3C1] p-8 xl:p-16">
            <div className="max-w-[1060px] mx-auto grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 place-items-center gap-x-3 gap-y-6 sm:gap-y-16 xl:gap-y-24">
              {partnersAboutValue?.partners?.map((data, i) => (
                <div
                  key={i}
                  className="max-w-[244px] bg-white px-8 py-16 flex flex-col items-center gap-y-5 rounded-lg h-full"
                >
                  <div className="max-h-[100px]">
                    <img
                      src={`https://buzgroupapi-eyb7ffhde4hvh4ex.germanywestcentral-01.azurewebsites.net/${data?.image}`}
                      alt="BUZ GROUP partners"
                      className="h-full"
                    />
                  </div>
                  <p className="text-lg font-bold">{data?.name}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    }    
    </>
  );
}

export default Partners