import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import axios from 'axios';

// Функция для установки языка на основе URL или страны
const setLanguageFromURLorCountry = async () => {
  const storedLang = localStorage.getItem('i18nextLng');
  
  // Получаем язык из URL (например, /ru/about)
  const pathLang = window.location.pathname.split('/')[1]; // это будет 'ru', 'tr', 'en', и т.д.
  console.log(pathLang);
  

  // Универсальная нормализация языка
  const normalizeLanguage = (lang) => {
    if (!lang) return null;
    const match = lang.match(/^[a-z]{2}/i); // Ищем первые 2 буквы (например, ru, en)
    return match ? match[0].toLowerCase() : null;
  };

  let normalizedLang = normalizeLanguage(pathLang);

  // Если язык в URL не найден или некорректен, проверяем localStorage
  if (!normalizedLang && storedLang) {
    normalizedLang = normalizeLanguage(storedLang);
  }

  // Если язык в URL или в localStorage некорректен, определяем язык по геолокации
  if (!normalizedLang) {
    try {
      const response = await axios.get('https://ipapi.co/json/');
      const data = await response.data;
      const countryCode = data.country_code;

      const countryLanguageMap = {
        RU: 'ru',
        TR: 'tr',
        AZ: 'az',
        GE: 'ka',
        US: 'en',
        GB: 'en',
      };

      const language = countryLanguageMap[countryCode] || 'en';
      i18n.changeLanguage(language);
      localStorage.setItem('i18nextLng', language); // Сохраняем язык
    } catch (error) {
      console.error("Не удалось определить язык по стране:", error);
      i18n.changeLanguage('en');
      localStorage.setItem('i18nextLng', 'en'); // Сохраняем fallback язык
    }
  } else {
    // Если язык найден в URL или в localStorage, меняем его
    i18n.changeLanguage(normalizedLang);
    localStorage.setItem('i18nextLng', normalizedLang); // Сохраняем язык
  }
};

// Инициализация i18n
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['localStorage', 'queryString', 'cookie'], // Сначала проверяем сохраненные значения
      cache: ['localStorage', 'cookie'], // Сохраняем язык в localStorage и cookie
    },
    interpolation: {
      escapeValue: false,
    },
  });

// Вызываем функцию установки языка при первой загрузке
setLanguageFromURLorCountry();

export default i18n;
