import React from 'react'
import SingleProd from '../components/SingleProd'

const SingleProdPage = () => {
  return (
    <>
        <SingleProd/>
    </>
  )
}

export default SingleProdPage