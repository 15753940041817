import React from 'react'
import Products from '../components/Products';
import { Helmet } from 'react-helmet';
import titles from './titles/titles';

const ProductsPage = () => {
    

  return (
    <>
        <Helmet>
          <title>
            {titles.productsTit}
          </title>
        </Helmet>
        <Products/>
    </>
  )
}

export default ProductsPage