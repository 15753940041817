import { initialState } from "./initialState";
import { createSlice } from "@reduxjs/toolkit";

export const MainSlice = createSlice({
  name: "MAIN_SLICE",
  initialState: initialState,

  reducers: { 
     // Вызов функции, чтобы закончить старт процесса загрузки 
     isLoadingFunc: (state) => {
      state.loading = false;
    },
    // Вызов функции, чтобы начать старт процесса загрузки 
    isLoadingStart: (state) => {
      state.loading = true;
    },
    

    // Функция присвоения к переменной значений banners на главной странице
    bannersDataFunc: (state, action) => {
      state.bannersDataValue = action.payload;
    },
    
    // Функция присвоения к переменной фото для карусели на главной странице
    carouselImagesFunc: (state, action) => {
      state.carouselImagesValue = action.payload;

      state.loading = false
       
    },

     // Функция присвоения к переменной категорий из семи для отображения на главной странице
    sevenCategoriesFunc: (state, action) => {
      state.sevenCategoriesValue = action.payload;
      
    },

    // Функция присвоения к переменной значений партеров на главной странице
    partenrsDataFunc: (state, action) => {
      state.partnersDataValue = action.payload;

    },
    
    // Функция присвоения к переменной для отображения новостей на главной странице
    blogsHomePageFunc: (state, action) => {
      state.blogsHomePageValue = action.payload;
    },

   


    // Функция присвоения к переменной для отображения контактов адресов и т.д.
    contactsFunc: (state, action) => {
      state.contactsValue = action.payload;

      state.loading = false;
    },
    // Функция присвоения к переменной для отображения новостей на странице новестей
    getBlogsAllNewsFunc: (state, acton) => {
      state.blogsAllNewsValue = acton.payload;

      // Отключение загрузки
      // state.loading = false;
    },

    // Функция присвоения к переменной для отображения новости на синг странице

    newsIDFunc: (state, action) => {
      state.newsIDvalue = action.payload;

      // Отключение загрузки 
      state.loading = false;
    },

    // Функция присвоения к переменной для отображения списка категорий
    catListFunc: (state, action) => {
      state.catListValue = action.payload;
    },

    // Функция присвоения к переменной для отображения списка товаров
    productsListFunc: (state, action) => {
      state.productsListValue = action.payload;

      // Закрываем загрузку burger menu Products.jsx
      state.loading = false;
    },
    // Функция присвоения к переменной для отображения товара

    productIDfunc: (state, action) => {
      state.productIDvalue = action.payload;
      // Завершение загрузки
      // state.loading = false
    },

    // Функция присвоения к переменной для много продоваемых товаров товара
    mostSelListFunc: (state, action) => {
      state.mostSelListValue = action.payload;
    },
    // Функция присвоения к переменной для информации о компании и работников

    aboutUsFunc: (state, action) => {
      state.aboutUsValue = action.payload; 
      state.loading =false
    },

    // Функция присвоения к переменной для описания партнеров компании
    partersAboutFunc:(state, action)=>{
      state.partnersAboutValue = action.payload
    }
  },
});

export const Data = MainSlice.reducer;
export const {
  carouselImagesFunc,
  partenrsDataFunc,
  bannersDataFunc,
  sevenCategoriesFunc,
  contactsFunc,
  blogsHomePageFunc,
  getBlogsAllNewsFunc,
  newsIDFunc,
  catListFunc,
  productsListFunc,
  productIDfunc,
  mostSelListFunc,
  aboutUsFunc,
  isLoadingFunc,
  isLoadingStart,
  partersAboutFunc
} = MainSlice.actions;
