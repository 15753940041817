import React, { useEffect } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay, EffectFade } from "swiper/modules";
import { useDispatch, useSelector } from "react-redux";
import { getCarouselImages } from "../action/MainAction";
import Loading from "./Loading";
import { isLoadingStart } from "../redux/MainReducer";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';



const Hero = () => {

  const dispatch = useDispatch();

  // Вызов /api/carousel-images/
  useEffect(() => {
    dispatch(isLoadingStart());
    dispatch(getCarouselImages());
  }, [dispatch]);

  // Вызов переменной содержащий фото для карусели
  const carouselImages = useSelector((state) => state.Data.carouselImagesValue);
  const loading = useSelector((state) => state.Data.loading);


  return (
    <>
    {loading? <Loading/>:
    
      <Swiper
        effect="fade" // Эффект появления
        centeredSlides={true}
        autoplay={{
          delay: 4500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, EffectFade]}
        className="heroSwiper"
        // data-aos = "zoom-in" 
      >
        {carouselImages?.map((image, i) => (
          <SwiperSlide key={i}>
            <div className="w-full max-h-[630px]" >
            <LazyLoadImage
                className="w-full h-full"
                alt="BUZ GROUP carousel images"
                effect="blur"
                width="100%"
                src={`https://buzgroupapi-eyb7ffhde4hvh4ex.germanywestcentral-01.azurewebsites.net/${image}`}
                 />
              {/* <img loading="lazy" src={`https://buzgroupapi-eyb7ffhde4hvh4ex.germanywestcentral-01.azurewebsites.net/${image}`} alt="BUZ GROUP carousel images" className="w-full h-full" /> */}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    }
    
    </>
  );
};

export default Hero;
