import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { getPartnersData } from '../action/MainAction'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import i18next from 'i18next'



const PartnersSlider = () => { 

    const { t } = useTranslation();

    const dispatch = useDispatch()

    // Вызов переменной содержащий данные о партнеров
    const partnersDataValue = useSelector(state=>state.Data.partnersDataValue)

    // Вызов api/partners/
    useEffect(()=>{
        dispatch(getPartnersData()) 
    },[dispatch])

    // Используем код языка для отображения url www.site.com/en/...
    const lang = i18next?.language.toLowerCase()
    
  return (
    <div className="w-full mt-24 mx-auto bg-gradient-to-r from-[#50B3C1] to-[#5E2D7A] pt-[40px] pb-[92px] lg:pt-[70px] lg:pb-[136px]">

      {/* Content of Partners section */}
     
        <div className="max-w-[1248px] mx-auto flex flex-col justify-between items-start text-white mt-[70px] space-y-8 px-6">

          <h2  className="text-3xl md:text-4xl font-merri tracking-widest">{t("partners")}</h2>

            <p className="text-base md:text-xl font-roboto font-light tracking-widest">
              {t('buzGroupPartnership')}
            </p>

          <Link to={`${lang}/partnerspage`}>
            
              <button className="text-sm font-roboto py-5 px-12 bg-indigo hover:bg-[#442159] border border-indigo hover:border-white ease-in-out duration-500 tracking-widest rounded">
                  {t('seeAll')}
              </button>
          </Link>
        </div>

      {/* Slider of Partners */}
      <div className="overflow-hidden group">
        <div className="w-[1440px] h-[90px] mt-20 flex space-x-7 animate-loop-scroll group-hover:paused">
          {partnersDataValue?.map((image, i) => (
            <div
              className="min-w-[200px] max-h-[90px] bg-white flex justify-between items-center"
              key={i}
            >
              <img
                src={`https://buzgroupapi-eyb7ffhde4hvh4ex.germanywestcentral-01.azurewebsites.net/${image?.image}`}
                alt="BUZ GROUP partners"
              />
            </div>
          ))}

        </div>
      </div>
    </div>
  );
}

export default PartnersSlider