const titles = {
  aboutTit: "About || Buz Group",
  homeTit: "Home Page || Buz Group",
  productsTit: "Products || Buz Group",
  contactsTit: "Contacts || Buz Group",
  newsTit: "News || Buz Group",
  partTit: "Partners || Buz Group",
};

export default titles;
