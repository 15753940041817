import { useEffect, useState } from 'react';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { useDispatch, useSelector } from 'react-redux';
import { getContacts } from '../action/MainAction';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import Loading from './Loading'
import axios from 'axios';
import Swal from 'sweetalert2';
import { isLoadingStart } from '../redux/MainReducer';


const Contact = () => {
  const [inpName, setInpName] = useState("");
  const [inpLastName, setInpLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [emailInp, setEmailInp] = useState("");
  const [text, setText] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  

  const nameChange = (e) => {
    setInpName(e.target.value);
  };
  const lastNameChange=(e)=>{
      setInpLastName(e.target.value)
  }
  const phoneChange=(value)=>{
      setPhone(value)
  }
  const emailChange=(e)=>{
      setEmailInp(e.target.value)
  }
  const textChange=(e)=>{
      setText(e.target.value) 
  }
  const checkboxChange = (e) => {
    setIsChecked(e.target.checked);
   
  };

  const dispatch = useDispatch()

  const { t } = useTranslation();

  // Вызов переменной содержащий данные о контактов
  const contactsValue = useSelector(state=>state.Data.contactsValue)

  // Вызов api/contacts/az/
  useEffect(() => {
    window.scrollTo(0,0)
    dispatch(isLoadingStart())
    if (i18next.language!=="dev") {
      dispatch(getContacts(i18next.language.toLowerCase()));
    }
  }, [dispatch, i18next.language]); 

  const loading= useSelector(state=>state.Data.loading)

  const [isLoading, setIsLoading] = useState(false)

  // Отправка данных на сервер при клике на send 
  const sendMessage = (e) => {
    e.preventDefault();
    setIsLoading(true)
  
    if (!isChecked) {
      Swal.fire("Please check the box!"); 
    } else {
      const fetchData = async () => {
        const data = {
          email: emailInp,
          comment: text,
          first_name: inpName,
          last_name: inpLastName,
          phone_number: phone,
        };
  
        try {
          const response = await axios.post(
            "https://buzgroupapi-eyb7ffhde4hvh4ex.germanywestcentral-01.azurewebsites.net/api/send-client-email/",
            data, // passing data in request
            {
              headers: {
                "Content-Type": "application/json", // specify that we are sending JSON
              },
            }
          );
          if (response.status === 200) {
            setIsLoading(false)
            Swal.fire("Success!", "Message sent successfully!", "success"); 
            
            setInpName('')
            setInpLastName('')
            setPhone('')
            setEmailInp('')
            setText('')
            setIsChecked(false)
          }
  
          // Handle server response
         
        } catch (error) {
          Swal.fire("Error", "Something went wrong, please try again.", "error"); 
          console.error(error);
        }
      };
      fetchData();
    }
  };
  
  
  
 
  return (
    <>
    {
      loading?<Loading/>:(
        <div className='border border-[#d4d0ca]'>
            <div className="bg-gradient-to-r from-[#50B3C1] to-[#5E2D7A] py-[82px]">
            <div className="max-w-[1200px] mx-auto text-white">
                <h3 className="text-center text-[32px]">{t('contactUs')}</h3>

            <div className="flex flex-wrap justify-center lg:justify-between gap-y-4 mt-[70px]">
              <div className="w-[195px] sm:w-[260px] max-h-[260px] flex flex-col items-center px-3">
                <div className="bg-[#FFFFFF33] p-3 rounded-full">
                  <svg className="p-1" width="50" height="51" viewBox="0 0 50 51" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_304_5941)">
                      <path
                        d="M25 0.5C14.5801 0.5 6.25 8.83008 6.25 19.25C6.25 22.8535 7.32422 25.6367 9.18945 28.459L23.0273 49.4551C23.4473 50.0898 24.1699 50.5 25 50.5C25.8301 50.5 26.5625 50.0801 26.9727 49.4551L40.8105 28.459C42.6758 25.6367 43.75 22.8535 43.75 19.25C43.75 8.83008 35.4199 0.5 25 0.5ZM25 29.6602C19.248 29.6602 14.5801 24.9922 14.5801 19.2305C14.5801 13.4688 19.248 8.80078 25 8.80078C30.752 8.80078 35.4199 13.4688 35.4199 19.2305C35.4199 24.9922 30.752 29.6602 25 29.6602ZM25 13C21.543 13 18.75 15.793 18.75 19.25C18.75 22.707 21.543 25.5 25 25.5C28.457 25.5 31.25 22.707 31.25 19.25C31.25 15.793 28.457 13 25 13Z"
                        fill="white" />
                    </g>
                    <defs>
                      <clipPath id="clip0_304_5941">
                        <rect width="50" height="50" fill="white" transform="translate(0 0.5)" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div>
                  <h6 className="text-xl font-bold text-center py-2">{t('address')}</h6>
                  {
                    contactsValue[0]?.addresses.map((data, i)=>(
                      <p key={i} className="text-center">{data}</p>

                    ))
                  }
                </div>
              </div>
              <div className="w-[195px] sm:w-[260px] flex flex-col items-center px-3">
                <div className="bg-[#FFFFFF33] p-3 rounded-full">
                  <svg className="p-1" width="50" height="51" viewBox="0 0 50 51" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_304_5941)">
                      <path
                        d="M25 0.5C14.5801 0.5 6.25 8.83008 6.25 19.25C6.25 22.8535 7.32422 25.6367 9.18945 28.459L23.0273 49.4551C23.4473 50.0898 24.1699 50.5 25 50.5C25.8301 50.5 26.5625 50.0801 26.9727 49.4551L40.8105 28.459C42.6758 25.6367 43.75 22.8535 43.75 19.25C43.75 8.83008 35.4199 0.5 25 0.5ZM25 29.6602C19.248 29.6602 14.5801 24.9922 14.5801 19.2305C14.5801 13.4688 19.248 8.80078 25 8.80078C30.752 8.80078 35.4199 13.4688 35.4199 19.2305C35.4199 24.9922 30.752 29.6602 25 29.6602ZM25 13C21.543 13 18.75 15.793 18.75 19.25C18.75 22.707 21.543 25.5 25 25.5C28.457 25.5 31.25 22.707 31.25 19.25C31.25 15.793 28.457 13 25 13Z"
                        fill="white" />
                    </g>
                    <defs>
                      <clipPath id="clip0_304_5941">
                        <rect width="50" height="50" fill="white" transform="translate(0 0.5)" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div>
                  <h6 className="text-xl font-bold text-center py-2">{t('contactUs')}</h6>
                  {
                    contactsValue[0]?.phone_numbers?.map((data, i)=>(
                      <p key={i} className="text-center">{data}</p>
                    ))
                  }
                  
                </div>
              </div>
              <div className="w-[195px] sm:w-[260px] flex flex-col items-center px-3">
                <div className="bg-[#FFFFFF33] p-3 rounded-full">
                  <svg className="p-1" width="50" height="51" viewBox="0 0 50 51" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_304_5941)">
                      <path
                        d="M25 0.5C14.5801 0.5 6.25 8.83008 6.25 19.25C6.25 22.8535 7.32422 25.6367 9.18945 28.459L23.0273 49.4551C23.4473 50.0898 24.1699 50.5 25 50.5C25.8301 50.5 26.5625 50.0801 26.9727 49.4551L40.8105 28.459C42.6758 25.6367 43.75 22.8535 43.75 19.25C43.75 8.83008 35.4199 0.5 25 0.5ZM25 29.6602C19.248 29.6602 14.5801 24.9922 14.5801 19.2305C14.5801 13.4688 19.248 8.80078 25 8.80078C30.752 8.80078 35.4199 13.4688 35.4199 19.2305C35.4199 24.9922 30.752 29.6602 25 29.6602ZM25 13C21.543 13 18.75 15.793 18.75 19.25C18.75 22.707 21.543 25.5 25 25.5C28.457 25.5 31.25 22.707 31.25 19.25C31.25 15.793 28.457 13 25 13Z"
                        fill="white" />
                    </g>
                    <defs>
                      <clipPath id="clip0_304_5941">
                        <rect width="50" height="50" fill="white" transform="translate(0 0.5)" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div>
                  <h6 className="text-xl font-bold text-center py-2">Email</h6>
                  {
                    contactsValue[0]?.emails?.map((data, i)=>(
                      <p key={i} className="text-center">{data}</p>
                    ))
                  }
                </div>
              </div>
              <div className="w-[195px] sm:w-[260px] flex flex-col items-center px-3">
                <div className="bg-[#FFFFFF33] p-3 rounded-full">
                  <svg className="p-1" width="50" height="51" viewBox="0 0 50 51" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_304_5941)">
                      <path
                        d="M25 0.5C14.5801 0.5 6.25 8.83008 6.25 19.25C6.25 22.8535 7.32422 25.6367 9.18945 28.459L23.0273 49.4551C23.4473 50.0898 24.1699 50.5 25 50.5C25.8301 50.5 26.5625 50.0801 26.9727 49.4551L40.8105 28.459C42.6758 25.6367 43.75 22.8535 43.75 19.25C43.75 8.83008 35.4199 0.5 25 0.5ZM25 29.6602C19.248 29.6602 14.5801 24.9922 14.5801 19.2305C14.5801 13.4688 19.248 8.80078 25 8.80078C30.752 8.80078 35.4199 13.4688 35.4199 19.2305C35.4199 24.9922 30.752 29.6602 25 29.6602ZM25 13C21.543 13 18.75 15.793 18.75 19.25C18.75 22.707 21.543 25.5 25 25.5C28.457 25.5 31.25 22.707 31.25 19.25C31.25 15.793 28.457 13 25 13Z"
                        fill="white" />
                    </g>
                    <defs>
                      <clipPath id="clip0_304_5941">
                        <rect width="50" height="50" fill="white" transform="translate(0 0.5)" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div>
                  <h6 className="text-xl font-bold text-center py-2">{t('workingHours')}</h6>

                    {/* Work days */}
                    {
                      contactsValue[0]?.working_days?.map((data, i)=>(

                        <p key={i} className="text-center">{data}</p>
                      ))
                    }
                 {/* Work house */}
                  {
                    contactsValue[0]?.working_hours?.map((data, i)=>(
                      <p key={i} className="text-center">{data}</p>
                    ))
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="max-w-[1240px] mx-auto flex flex-col lg:flex-row items-center lg:items-start justify-between my-16 px-5 gap-10">
          <div className="w-full sm:w-[580px] flex flex-col text-[#141130]">
            <h4 className="text-[28px] lg:text-[32px]">{t("anyQuestions")}</h4>
            <p className="text-base lg:text-xl mt-8">{t('ifYouHaveQuestions')}</p>
          </div>
          {/* Анкеда заполнения вопросов */}
          <form onSubmit={sendMessage} className="w-full sm:w-[580px] flex flex-col items-start gap-y-6" >
            <div className="w-full flex flex-col sm:flex-row gap-6">
              <div className="w-full py-2 px-3 border border-x-0 border-t-0 border-[#e4e4e7]">
                {/* Name input */}
                <label htmlFor="name" className="flex items-center gap-x-0.5">{t('name')}<span><svg width="8" height="8" viewBox="0 0 8 8"
                      fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M3.45455 7.40909L3.54545 4.90909L1.43182 6.25L0.886364 5.29545L3.11364 4.13636L0.886364 2.97727L1.43182 2.02273L3.54545 3.36364L3.45455 0.863636H4.54545L4.45455 3.36364L6.56818 2.02273L7.11364 2.97727L4.88636 4.13636L7.11364 5.29545L6.56818 6.25L4.45455 4.90909L4.54545 7.40909H3.45455Z"
                        fill="#F31260" />
                    </svg></span></label>
                <input 
                type="text" 
                id="name" 
                placeholder={t('name')} 
                className="w-full border-none outline-none"
                value={inpName}
                onChange={nameChange}
                required
                
                
                />
              </div>
              <div className="w-full py-2 px-3 border border-x-0 border-t-0 border-[#e4e4e7]">
                <label htmlFor="lastname" className="flex items-center gap-x-0.5">{t('surname')}<span><svg width="8" height="8" viewBox="0 0 8 8"
                      fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M3.45455 7.40909L3.54545 4.90909L1.43182 6.25L0.886364 5.29545L3.11364 4.13636L0.886364 2.97727L1.43182 2.02273L3.54545 3.36364L3.45455 0.863636H4.54545L4.45455 3.36364L6.56818 2.02273L7.11364 2.97727L4.88636 4.13636L7.11364 5.29545L6.56818 6.25L4.45455 4.90909L4.54545 7.40909H3.45455Z"
                        fill="#F31260" />
                    </svg></span></label>
                    {/* Lastname Input */}
                <input type="text" 
                id="lastname" 
                placeholder={t('surname')} 
                className="w-full border-none outline-none"
                value={inpLastName}
                onChange={lastNameChange}
                required
                />
                
              </div>
            </div>
            <div className="w-full flex flex-col sm:flex-row  gap-6">
              <div className="w-full px-3 border border-x-0 border-t-0 border-[#e4e4e7]">
                
                {/* Telefon input  */}
                <div>
                  <label htmlFor="phone" className="flex items-center gap-x-0.5">{t('phone')}<span><svg width="8" height="8"
                      viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M3.45455 7.40909L3.54545 4.90909L1.43182 6.25L0.886364 5.29545L3.11364 4.13636L0.886364 2.97727L1.43182 2.02273L3.54545 3.36364L3.45455 0.863636H4.54545L4.45455 3.36364L6.56818 2.02273L7.11364 2.97727L4.88636 4.13636L7.11364 5.29545L6.56818 6.25L4.45455 4.90909L4.54545 7.40909H3.45455Z"
                        fill="#F31260" />
                    </svg></span>
                  </label>
                  <PhoneInput
                    placeholder={t('phone')}
                    defaultCountry="az"
                    value={phone}
                    onChange={(phone) => phoneChange(phone)}
                    required
                  />
                </div>
              </div>
              <div className="w-full py-2 px-3 border border-x-0 border-t-0 border-[#e4e4e7]">
                <label htmlFor="email" className="flex items-center gap-x-0.5">E-mail<span>
                  <svg width="8" height="8"
                      viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M3.45455 7.40909L3.54545 4.90909L1.43182 6.25L0.886364 5.29545L3.11364 4.13636L0.886364 2.97727L1.43182 2.02273L3.54545 3.36364L3.45455 0.863636H4.54545L4.45455 3.36364L6.56818 2.02273L7.11364 2.97727L4.88636 4.13636L7.11364 5.29545L6.56818 6.25L4.45455 4.90909L4.54545 7.40909H3.45455Z"
                        fill="#F31260" />
                    </svg></span></label>
                <input 
                type="email" 
                id="email" 
                placeholder="..." 
                className="w-full border-none outline-none"
                value={emailInp}
                onChange={emailChange} 
                required
                
                />
              </div>
            </div>
            <div className="w-full">
              <label htmlFor="message" className="text-[#5f5753] px-3">{t('messageBox')}</label>
              <textarea 
              id="message" 
              name="message" 
              rows="7" cols="50" 
              placeholder="Text..."
              className="w-full py-1 px-3 outline-none border border-[#e4e4e7] resize-none"
              value={text}
              onChange={textChange}
              required


                
                ></textarea>
            </div>
            <div className="flex items-start mb-4">
              {/* Checkbox input */}
              <input 
              id="default-checkbox" 
              type="checkbox" 
              value={isChecked}
              className="mt-1.5"
              onChange={checkboxChange}
              
              
              />
              <label htmlFor="default-checkbox" className="max-w-[500px] ms-2 text-sm text-[#7a7a7a] leading-6">
                {t('emailConsent')}</label>
            </div>
            <button className="flex text-white text-sm rounded bg-[#5E2D7A] hover:bg-[#442159] border border-indigo hover:border-white ease-in-out duration-500 tracking-widest px-20 py-4 sm:px-24 lg:px-[136px] sm:py-5">{t("send")}</button> 
          </form>

        </div>
        <div className="max-w-[1240px] mx-auto flex flex-col lg:flex-row items-center lg:items-start justify-between my-16 px-5 gap-10">
            <div className="w-full sm:w-[580px] flex flex-col text-[#141130]">
                <h4 className="text-[28px] lg:text-[32px]">{t('findUsHere')}</h4>
                <p className="text-base lg:text-xl mt-8">{contactsValue[0]?.addresses[0]}</p>
            </div>
            <div className="max-w-[690px] lg:w-[690px]">
          <iframe
               src={`https://www.google.com/maps?q=${encodeURIComponent(contactsValue[0]?.addresses[0])}&output=embed`}
                className="w-full h-[400px] border-none"
                allowFullScreen
            ></iframe>
        </div>

        </div>
      </div>


      )
    }
    </>
  )
}

export default Contact