import React from 'react'
import NewsHomeSlider from '../components/NewsHomeSlider'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom';
import i18next from 'i18next';

const NewsHome = () => {

  const { t } = useTranslation(); 

    // Используем код языка для отображения url www.site.com/en/...
    const lang = i18next?.language.toLowerCase()

  return (
    <>
        <div className='max-w-[1248px] m-auto flex justify-between flex-col gap-x-20 gap-y-6 md:flex-row px-6 mt-16 lg:mt-[120px]'>

              <h3 className='text-3xl md:text-4xl md:min-w-[320px] lg:w-[320px] font-merri'>{t('stayInformed')}</h3>
              
              <div className='w-full md:max-w-[550px] flex flex-col gap-y-6 md:gap-y-12'>
                  <p className='text-base md:text-xl font-roboto'>{t('buzGroupTechnology')}.</p>
                  
                  <Link to={`${lang}/newspage`}>
                      <button className='w-[144px] text-white text-sm py-4 px-6 bg-indigo hover:bg-[#442159] border border-indigo hover:border-white ease-in-out duration-500 tracking-widest rounded font-roboto'>{t('news')}</button>
                  </Link>
              </div>
          </div>
         
        <NewsHomeSlider/> 
    </>
  )
}

export default NewsHome