import React from 'react'
import Hero from '../components/Hero'
import HomeGrids from '../components/HomeGrids'
import NewsHome from '../components/NewsHome'
import PartnersSlider from '../components/PartnersSlider'
import { Helmet } from 'react-helmet'
import titles from './titles/titles'
import ParticlesBackground from '../components/common/Sparticles/ParticlesBackground'
import { useDispatch, useSelector } from 'react-redux'
import { isLoadingStart } from '../redux/MainReducer'
import Loading from '../components/Loading'



const Home = () => { 


  return (
    <>
      <Helmet>
        <title>
          {titles.homeTit}
        </title>
      </Helmet>
     
        <ParticlesBackground/>
        <Hero/>
        <HomeGrids/>
        <PartnersSlider/> 
        <NewsHome/>
    
    </>
  )
}

export default Home