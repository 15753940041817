import React from "react";
import Partners from "../components/Partners";
import { Helmet } from "react-helmet";
import titles from "./titles/titles";
import ParticlesBackground from "../components/common/Sparticles/ParticlesBackground";

const PartnersPage = () => {

  console.log(titles);
  
  return (
    <>
      <Helmet>
        <title>{titles.partTit}</title>
      </Helmet>
      <ParticlesBackground/>
      <Partners />
    </>
  );
};

export default PartnersPage;
