import React, { useEffect } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

import "swiper/css/effect-fade";

// import required modules
import { Autoplay, EffectFade } from "swiper/modules"; // Импорт Autoplay
import { useDispatch, useSelector } from "react-redux";
import { getBannersData } from "../action/MainAction";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import i18next from "i18next";

const UnderNav = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  // Вызов переменной содержащий данные о BANNERS
  const bannersDataValue = useSelector((state) => state.Data.bannersDataValue);

  // Вызов /api/banners/
  useEffect(() => {
    dispatch(getBannersData());
  }, [dispatch]);

  // Используем код языка для отображения url www.site.com/en/...
  const lang = i18next?.language.toLowerCase()

  return (
    <Swiper
      spaceBetween={30}
      effect={"fade"}
      navigation={false}
      pagination={{
        clickable: false,
      }}
      autoplay={{
        delay: 3000,
        disableOnInteraction: true,
      }}
      modules={[EffectFade, Autoplay]}
      className="mySwiper h-20 lg:h-32 flex items-center bg-gradient-to-r from-[#50B3C1] to-[#5E2D7A]"
    >
      {bannersDataValue.map((data, i) => (
        <SwiperSlide key={i}>
          <div className="h-20 lg:h-32 flex items-center bg-gradient-to-r from-[#50B3C1] to-[#5E2D7A]">
            <div className="max-w-[1420px] w-full m-auto flex items-end text-sm sm:text-base text-white pl-6">
              <img
                loading="lazy"
                src={`https://buzgroupapi-eyb7ffhde4hvh4ex.germanywestcentral-01.azurewebsites.net/${data?.image}`}
                alt="BUZ GROUP banners"
                className="w-6 h-6 sm:w-8 sm:h-8"
              />
              <p className="ml-2 font-roboto">
              
                <Link
                  to={`${lang}/singleprod/${data?.product?.id}/${encodeURIComponent(
                    data?.product?.title.replace(/\s+/g, "-").toLowerCase()
                  )}`}
                >
                  <span className="sm:text-lg underline underline-offset-4 mr-2">
                    {data?.product?.title}
                  </span>
                  {t("getToKnowService")}
                </Link>
              </p>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default UnderNav;
