import React, { useEffect, useState } from 'react';
import SingleProdModal from './SingleProdModal';
import { useDispatch, useSelector } from 'react-redux';
import { getProductID } from '../action/MainAction';
import { useParams } from 'react-router-dom';
import DOMPurify from "dompurify";
import { useTranslation } from 'react-i18next';
import { isLoadingStart } from '../redux/MainReducer';
import Loading from './Loading';

import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles

// ..
AOS.init({delay:400});


const SingleProdsMain = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();


  // Получение данных продукта из Redux
  const productIDvalue = useSelector((state) => state.Data.productIDvalue);

  // Локальный state для слайдера
  const [sliderData, setSliderData] = useState(null);

  // Orxan's code of open model
  // const [openModal, setOpenModal] = useState(false);

  const [modalActive, setModalActive] = useState(false);

  useEffect(() => {
    // Если находится в той же странице и нажать на товар страница начнет с головы 
    dispatch(isLoadingStart())
    window.scrollTo({top:0});

    // Вызов фцнкции для синг товара
    if(id){
      dispatch(getProductID(id));
    }
  }, [dispatch, id]);

  const loading = useSelector(state=>state.Data.loading)

  useEffect(() => {
    if (productIDvalue) {
      const images = productIDvalue.images?.length
        ? productIDvalue.images
        : [productIDvalue.cover_image];
      setSliderData({ id: 0, value: images[0] });
    }
  }, [productIDvalue]);

  // Смена изображений товара при нажатии 
  const handleClick = (index) => {
    const images = productIDvalue.images?.length
      ? productIDvalue.images
      : [productIDvalue.cover_image];
    setSliderData({ id: index, value: images[index] });
  };

  const images = productIDvalue.images?.length
    ? productIDvalue.images
    : [productIDvalue.cover_image];


  return (
    <>
    {loading?<Loading/>:(

      <div> 
        <div className="pt-[70px] pb-[115px]"> 
          <div className="max-w-[1248px] mx-auto px-6">
            <div className="flex items-center lg:items-start flex-col lg:flex-row gap-y-10 gap-x-28">
              {/* Image Gallery of Product */}
              <div className="max-w-[510px]">
                <div className="flex flex-col-reverse sm:flex-row justify-between gap-5">
                  <div data-aos="fade-right">

                    <div className="max-w-[389px] sm:w-[65px] sm:h-[389px] overflow-hidden flex flex-row sm:flex-col gap-x-4 sm:gap-y-4 transition-transform duration-500">
                      {images.map((img, i) => (
                        <div
                          key={i}
                          className={`w-[65px] border border-[#eaeaea] p-[1.6px] rounded-lg cursor-pointer ${
                            sliderData?.id === i ? 'bg-gradient-to-r from-[#50B3C1] to-[#5E2D7A]' : ''
                          }`}
                          onClick={() => handleClick(i)}
                        >
                          <div className="w-full bg-white rounded-lg p-[6px]">
                            <img src={`https://buzgroupapi-eyb7ffhde4hvh4ex.germanywestcentral-01.azurewebsites.net/${img}`} alt={`Product ${i}`} className="w-full" />
                          </div>
                        </div>
                      ))}
                    </div>

                  </div>
                <div data-aos="fade-right">
                  <div className="w-[384px]">
                    <img src={`https://buzgroupapi-eyb7ffhde4hvh4ex.germanywestcentral-01.azurewebsites.net/${sliderData?.value}`} alt="Selected product" className="w-full" />
                  </div>
                  </div>
                </div>
                <div onClick={() => setModalActive(true)} className="flex justify-center sm:justify-end">
                  <button className="w-[352px] h-[56px] flex justify-center items-center text-white bg-[#5E2D7A] rounded-lg mt-8">
                    {t('sendQuery')}
                    <svg className='ml-5' width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M19.5096 2.95747C19.5583 2.7953 19.5622 2.62297 19.5208 2.45879C19.4794 2.2946 19.3943 2.14468 19.2746 2.02495C19.1549 1.90523 19.005 1.82016 18.8408 1.77879C18.6766 1.73741 18.5043 1.74128 18.3421 1.78997L2.71709 6.47747C2.54439 6.52945 2.39035 6.63014 2.27344 6.76748C2.15653 6.90481 2.08172 7.07295 2.05797 7.25173C2.03422 7.43051 2.06254 7.61235 2.13954 7.77544C2.21655 7.93853 2.33895 8.07595 2.49209 8.17122L8.23959 11.7375L6.07584 13.9C5.98626 13.9864 5.91479 14.0898 5.86561 14.2042C5.81642 14.3185 5.7905 14.4415 5.78936 14.566C5.78822 14.6905 5.81188 14.814 5.85897 14.9292C5.90605 15.0444 5.97561 15.1491 6.0636 15.2372C6.15158 15.3253 6.25622 15.3949 6.37141 15.4421C6.48661 15.4893 6.61004 15.5131 6.73452 15.5121C6.859 15.511 6.98203 15.4852 7.09644 15.4362C7.21084 15.3871 7.31432 15.3157 7.40084 15.2262L9.56459 13.0637L13.1296 18.8075C13.2249 18.9606 13.3623 19.083 13.5254 19.16C13.6885 19.237 13.8703 19.2653 14.0491 19.2416C14.2279 19.2178 14.396 19.143 14.5333 19.0261C14.6707 18.9092 14.7714 18.7552 14.8233 18.5825L19.5096 2.95747ZM10.9258 11.7L13.6246 16.05L16.6458 5.97997L10.9258 11.7ZM15.3196 4.65372L5.24959 7.67497L9.59959 10.375L15.3196 4.65372Z" fill="white"/>
                    </svg>
                  </button>
                </div>
              </div>
              {/* Description */}
              <div data-aos="zoom-in">
                <div className="min-w-[340px] md:pl-3 ">
                  <h2 className="text-2xl text-[#111827]">{productIDvalue?.title}</h2>
                  
                  <p className="xs mt-[15px] break-words whitespace-normal"
                  
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(productIDvalue?.details),
                    }}></p>
                </div>

              </div>

            </div>
          </div>
        </div>
        <SingleProdModal active={modalActive} setActive={setModalActive}  productTitle={productIDvalue?.title}/> 
      </div>

    )}
    </>
  );
};

export default SingleProdsMain;
