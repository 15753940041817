import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import { GrFormSearch } from "react-icons/gr";
import {AiOutlineClose, AiOutlineMenu} from "react-icons/ai"
import LanguageDropdown from './LanguageDropdown';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import axios from 'axios';
import HomeResSearchModal from './HomeResSearchModal';

const MobileNav = () => {

    const { t } = useTranslation();
    const [nav, setNav] = useState(false);
    const [search, setSearch] = useState("");
    const [results, setResults] = useState([]);

    const handleNav = () => {
      setNav(!nav);
      setSearch("");
    };

    useEffect(() => {
      console.log("Nav state changed:", nav);
    }, [nav]);

    // Используем код языка для отображения url www.site.com/en/...
    const lang = i18next?.language.toLowerCase();

    // Функция для обновления значения поиска
    const changeSearch = (value) => {
      setSearch(value);
    };

    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
      setIsLoading(true);
      // Проверяем длину строки, чтобы отправить запрос только если длина больше или равна 3 символам
      if (search.length >= 3) {
        const fetchData = async () => {
          try {
            const response = await axios.get(
              `https://buzgroupapi-eyb7ffhde4hvh4ex.germanywestcentral-01.azurewebsites.net/api/search/?query=${search}`
            );

            setResults(response.data);
            setIsLoading(false);
          } catch (error) {
            console.error(error);
          }
        };
        fetchData();
      }
    }, [search]); 

    
  return ( 
    <>
        <div className='flex items-center lg:hidden'>
            <LanguageDropdown/>
            <div onClick={handleNav} className="">
                {nav ? <AiOutlineClose size={25}/> : <AiOutlineMenu size={25}/>}
            </div>
        </div>
        <div className={nav ? "flex flex-col-reverse fixed right-0 top-20 w-[60%] text-white bg-gradient-to-r from-[#50B3C1] to-[#5E2D7A] border-l border-l-black lg:hidden ease-in-out duration-500" : "fixed right-[-100%]"}>
            <div className="min-w-[200px] flex order-1 bg-white text-black border border-l-0">
                <input type="search" 
                placeholder={t("productsServicesAndMore")} 
                className="w-full border-none outline-none caret-black px-2"
                value={search}
                onChange={(e) => changeSearch(e.target.value)}
                
                />
                <div className="bg-[#50B3C1] w-9 h-9">
                    <GrFormSearch size={35} className="text-white"/>
                </div>
                {search?.length>=3 && <HomeResSearchModal data={results} loading={isLoading} />}
            </div>
            <nav className="flex flex-col">
                <ul className="flex flex-col gap-x-16 text-sm uppercase">
                    <li className="p-4 pl-8 border border-t-0 border-x-0 border-b-black" 
                        onClick={() => setNav(false)}>
                        <Link to={`${lang}/about`}>{t("aboutUs")}</Link></li>
                    <li className="p-4 pl-8 border border-t-0 border-x-0 border-b-black" 
                        onClick={() => setNav(false)}>
                        <Link to={`${lang}/partnerspage`}>{t("partners")}</Link> </li>
                    <li className="p-4 pl-8 border border-t-0 border-x-0 border-b-black" 
                        onClick={() => setNav(false)}>
                        <Link to={`${lang}/contact`}>{t("contact")}</Link></li>
                </ul>
            </nav>
            <div className="flex">
                <ul className="w-full flex flex-col text-base justify-between uppercase">
                    <li className="p-4 border border-t-0 border-x-0 border-b-black" 
                        onClick={() => setNav(false)}>
                        <Link to="/">{t('home')}</Link></li>
                    <li className="p-4 border border-t-0 border-x-0 border-b-black" 
                        onClick={() => setNav(false)}>{t("services")}</li>
                    <li className="p-4 border border-t-0 border-x-0 border-b-black" 
                        onClick={() => setNav(false)}>
                        <Link to={`${lang}/productspage`}>{t("products")}</Link></li>
                    <li className="p-4 border border-t-0 border-x-0 border-b-black" 
                        onClick={() => setNav(false)}>
                        <Link to={`${lang}/newspage`}>{t("news")}</Link></li>
                </ul>
            </div>
        </div>
    </>
  )
}

export default MobileNav