import { useState } from "react";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { IoCloseOutline } from "react-icons/io5";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";

const SingleProdModal = ({ active, setActive, productTitle }) => {
  const { t } =useTranslation()
  // open, onClose

  const [inpName, setInpName] = useState("");
  const [inpLastName, setInpLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [emailInp, setEmailInp] = useState("");
  const [text, setText] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  const nameChange = (e) => {
    setInpName(e.target.value);
  };
  const lastNameChange=(e)=>{
      setInpLastName(e.target.value)
  }
  const phoneChange=(value)=>{
      setPhone(value)
  }
  const emailChange=(e)=>{
      setEmailInp(e.target.value)
  }
  const textChange=(e)=>{
      setText(e.target.value) 
  }
  const checkboxChange = (e) => {
    setIsChecked(e.target.checked);
   
  };

  // Если вы используете анимации и скрываете модальное окно через CSS,
  // строка if (!active) return null; не обязательна. Однако, если вам нужно
  // полностью исключать модальное окно из DOM, чтобы улучшить производительность
  // или избежать потенциальных багов, эта проверка необходима.
  // if(!active) return null;



  const sendMessage = (e) => {
    e.preventDefault();
  
    if (!isChecked) {
      Swal.fire("Please check the box!"); 
    } else {
      const fetchData = async () => {
        const data = {
          email: emailInp,
          comment: text, 
          first_name: inpName,
          last_name: inpLastName, 
          phone_number: phone,
          product:productTitle
        };
  
        try {
          const response = await axios.post(
            "https://buzgroupapi-eyb7ffhde4hvh4ex.germanywestcentral-01.azurewebsites.net/api/send-client-email/",  
            data, // передаем данные в запросе
            {
              headers: {
                "Content-Type": "application/json", // уточняем, что отправляем JSON
              },
            }
          );
          console.log(response);
          if (response.status === 200) {
            Swal.fire("Success!", "Message sent successfully!", "success"); // SweetAlert success message
            setActive(false)
            
            setInpName('')
            setInpLastName('')
            setPhone('')
            setEmailInp('')
            setText('')
            setIsChecked(false)
          }
          
  
          // Обработка ответа от сервера
          console.log(response.data);
        } catch (error) {
          console.error(error);
          Swal.fire("Error", "Something went wrong, please try again.", "error"); 
        }
      };
      fetchData();
    }
  };
  
  

  return (
    // onClick={()=setActive(false)}
    <div className={`fixed inset-0 flex justify-center items-center bg-black/70 z-30 
        ${ active ? "modal active" : "modal"}`} 
        onClick={() => setActive(false)}
    >
      {" "}
      {/* {active? modal active: modal} */}
      <div className={`fixed top-1/2 left-1/2 bg-[#fff] -translate-x-1/2 -translate-y-1/2 p-4 z-20
      w-full max-w-sm sm:max-w-md md:max-w-lg lg:max-w-xl xl:max-w-2xl max-h-[calc(100vh-40px)] overflow-auto
      
      
        ${active ? "modal__content active" : "modal__content"}`}
        onClick={(e) => {e.stopPropagation();}}>
        
        {/* Form tag START */}
        <form onSubmit={sendMessage}>

          <div className="w-full flex flex-col items-start gap-y-6">

            {/* Close Icon */}
            <div className="block ml-auto">
              <IoCloseOutline
                size={30}
                onClick={() => setActive(false)}
                className="block ml-auto cursor-pointer"
              />
            </div>

            {/* Name and Lastname input */}
            <div className="w-full flex flex-col sm:flex-row gap-6">
                {/* Name */}
              <div className="w-full py-2 px-3 border border-x-0 border-t-0 border-[#e4e4e7]">
                <label htmlFor="name" className="flex items-center gap-x-0.5">
                  {t('name')}
                  <span>
                    <svg
                      width="8"
                      height="8"
                      viewBox="0 0 8 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.45455 7.40909L3.54545 4.90909L1.43182 6.25L0.886364 5.29545L3.11364 4.13636L0.886364 2.97727L1.43182 2.02273L3.54545 3.36364L3.45455 0.863636H4.54545L4.45455 3.36364L6.56818 2.02273L7.11364 2.97727L4.88636 4.13636L7.11364 5.29545L6.56818 6.25L4.45455 4.90909L4.54545 7.40909H3.45455Z"
                        fill="#F31260"
                      />
                    </svg>
                  </span>
                </label>

                {/* Name input */}
                <input
                  type="text"
                  id="name"
                  placeholder={t('name')}
                  className="w-full border-none outline-none"
                  value={inpName}
                  onChange={nameChange}
                />
              </div>
                {/* Last name */}

              <div className="w-full py-2 px-3 border border-x-0 border-t-0 border-[#e4e4e7]">
                <label htmlFor="lastname" className="flex items-center gap-x-0.5">
                  {t('surname')}
                  <span>
                    <svg
                      width="8"
                      height="8"
                      viewBox="0 0 8 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.45455 7.40909L3.54545 4.90909L1.43182 6.25L0.886364 5.29545L3.11364 4.13636L0.886364 2.97727L1.43182 2.02273L3.54545 3.36364L3.45455 0.863636H4.54545L4.45455 3.36364L6.56818 2.02273L7.11364 2.97727L4.88636 4.13636L7.11364 5.29545L6.56818 6.25L4.45455 4.90909L4.54545 7.40909H3.45455Z"
                        fill="#F31260"
                      />
                    </svg>
                  </span>
                </label>

                <input
                  type="text"
                  id="lastname"
                  placeholder={t('surname')}
                  className="w-full border-none outline-none"
                  value={inpLastName}
                  onChange={lastNameChange}
                />
              </div>

            </div>
                {/* Phone add */}
            <div className="w-full flex flex-col sm:flex-row  gap-6">
              <div className="w-full py-2 px-3 border border-x-0 border-t-0 border-[#e4e4e7]">
                <div>
                  <label htmlFor="phone" className="flex items-center gap-x-0.5">
                    {t('phone')}
                    <span>
                      <svg
                        width="8"
                        height="8"
                        viewBox="0 0 8 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.45455 7.40909L3.54545 4.90909L1.43182 6.25L0.886364 5.29545L3.11364 4.13636L0.886364 2.97727L1.43182 2.02273L3.54545 3.36364L3.45455 0.863636H4.54545L4.45455 3.36364L6.56818 2.02273L7.11364 2.97727L4.88636 4.13636L7.11364 5.29545L6.56818 6.25L4.45455 4.90909L4.54545 7.40909H3.45455Z"
                          fill="#F31260"
                        />
                      </svg>
                    </span>
                  </label>

                  <PhoneInput
                    defaultCountry="az"
                    value={phone}
                    onChange={phoneChange}
                  />
                </div>
              </div>
              <div className="w-full py-2 px-3 border border-x-0 border-t-0 border-[#e4e4e7]">
                <label htmlFor="email" className="flex items-center gap-x-0.5">
                  E-mail
                  <span>
                    <svg
                      width="8"
                      height="8"
                      viewBox="0 0 8 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.45455 7.40909L3.54545 4.90909L1.43182 6.25L0.886364 5.29545L3.11364 4.13636L0.886364 2.97727L1.43182 2.02273L3.54545 3.36364L3.45455 0.863636H4.54545L4.45455 3.36364L6.56818 2.02273L7.11364 2.97727L4.88636 4.13636L7.11364 5.29545L6.56818 6.25L4.45455 4.90909L4.54545 7.40909H3.45455Z"
                        fill="#F31260"
                      />
                    </svg>
                  </span>
                </label>
                {/* Email Send */}
                <input
                  type="email"
                  id="email"
                  placeholder="..."
                  className="w-full border-none outline-none"
                  value={emailInp}
                  onChange={emailChange}
                  required
                />
              </div>
            </div>

            {/* Text textarea */}
            <div className="w-full">
              <label htmlFor="message" className="text-[#5f5753] px-3">
                {t('messageBox')}
              </label>

              
              <textarea
                id="message"
                name="message"
                rows="6"
                cols="50"
                placeholder="Text..."
                className="w-full py-1 px-3 outline-none border border-[#e4e4e7] resize-none"
                value={text}
                onChange={textChange}
              ></textarea>
            </div>

            {/* Captcha  */}
            <div className="flex items-start mb-4">
              <input
                id="default-checkbox"
                type="checkbox"
                className="mt-1.5"
                value={isChecked}
                onChange={checkboxChange}
              />
              <label
                htmlFor="default-checkbox"
                className="max-w-[500px] ms-2 text-sm text-[#7a7a7a] leading-6"
              >
                {t('emailConsent')}
              </label>
            </div>

            {/* Btn Send */}
            <div className="w-full flex items-center justify-center">
              <button className="flex text-white text-sm rounded bg-[#5E2D7A] px-20 py-4 sm:px-24 lg:px-[136px] sm:py-5">
                {t('sendQuery')}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SingleProdModal;
