import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import i18next from 'i18next'
import { getAboutUs } from '../action/MainAction'
import Loading from '../components/Loading'
import { isLoadingStart } from '../redux/MainReducer'
import { Helmet } from 'react-helmet'
import titles from './titles/titles'
import ParticlesBackground from '../components/common/Sparticles/ParticlesBackground'


const About = () => {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    // Вызов переменной содержащий данные о семи продуктах
    const aboutUsValue = useSelector(
      (state) => state.Data.aboutUsValue
    );

    const loading = useSelector((state) => state.Data.loading);

    // Вызов api/about-us/language/
    useEffect(() => {
      dispatch(isLoadingStart())
      

      window.scrollTo(0, 0);
      if (i18next.language !== "dev") {
        dispatch(getAboutUs(i18next.language.toLowerCase()));
      }
    }, [dispatch, i18next.language]);

    

  return (
    <>       
          <Helmet>
                <title>
                  {titles.aboutTit}
                </title>
          </Helmet>
          <ParticlesBackground/>
      
          {loading ? <Loading/> :
            <div className="pt-[70px]">
              {/* Head title */}
              <div className="max-w-[1248px] mx-auto px-6">
                <h3 className="text-[32px]">{aboutUsValue?.mission_title}</h3>
                <p className="text-[#5f5753] mt-8">{aboutUsValue?.mission_content}</p>
              </div>

              {/* Photo Company employees and work position  */}
              
              <div className="max-w-[1324px] mx-auto my-[60px] lg:my-[120px] ">
                <h3 className="max-w-[1248px] mx-auto text-[#141130] text-[28px] px-6">
                  {t('professionalTeam')}
                </h3>
                <div className="grid grid-cols-2 sm:flex sm:flex-wrap justify-center sm:gap-x-[30px] gap-y-5 sm:gap-y-10 py-5">
                  {aboutUsValue?.team_members?.map((data, i) => (
                    <div key={i} className=" max-w-[302px] px-8">
                      <div className="max-w-[240px] mb-8 rounded-full">
                        <img src={`https://buzgroupapi-eyb7ffhde4hvh4ex.germanywestcentral-01.azurewebsites.net/${data?.photo}`} alt="" className="w-full rounded-full hover:scale-105 duration-700" />
                      </div>
                      <p className="text-center text-[#141130]">{data?.name}</p>
                      <p className="text-center text-[#5f5753]">{data?.role}</p>
                    </div>
                  ))} 
                  
                </div>
              </div>

              <div className="w-full py-10 md:py-[70px] bg-gradient-to-r from-[#50B3C1] to-[#5E2D7A]">
                <div className="max-w-[1248px] mx-auto px-6 text-white flex flex-col lg:flex-row justify-between">
                  <div className="w-full lg:max-w-[477px] p-6">
                    <h3 className="text-[32px]">{aboutUsValue?.it_solutions_title}</h3>
                    <p className="max-w-[700px] lg:max-w-[430px] leading-6 mt-6 mb-6 lg:mb-10">
                      {aboutUsValue?.it_solutions_content}
                    </p>
                    <button className="text-[#5E2D7A] hover:text-white border border-white bg-white hover:bg-[#5E2D7A] duration-500 rounded py-4 px-8 md:py-[22px] md:px-14">
                      {t("ourServices")}
                    </button>
                  </div>

                  <div className="h-[1px] max-w-[700px] lg:w-[1px] bg-white"></div> 
                  
                  <div className="w-full lg:max-w-[477px] space-y-5 p-6">
                    <h3 className="text-[32px]">{aboutUsValue?.tech_products_title}</h3>
                    <p className="max-w-[700px] lg:max-w-[430px] leading-6">
                      {aboutUsValue?.tech_products_content}
                    </p>
                    <button className="text-[#50B3C1] hover:text-white border border-white bg-white hover:bg-[#50B3C1] duration-500 rounded py-4 px-8 md:py-[22px] md:px-14">
                      {t('ourProducts')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          
          }

        
    </>
  );
}

export default About