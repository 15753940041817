import React from 'react'
import SingleNews from '../components/SingleNews'

const SingleNewsPage = () => {
  return (
    <>
        <SingleNews/>
    </>
  )
}

export default SingleNewsPage